///<reference types="vite-plugin-sentry/client"/>
import * as Sentry from '@sentry/browser';
import {Integrations} from "@sentry/tracing";
import 'virtual:vite-plugin-sentry/sentry-config'

const dist = import.meta.env.VITE_PLUGIN_SENTRY_CONFIG.dist
const release = import.meta.env.VITE_PLUGIN_SENTRY_CONFIG.release
const enabled = import.meta.env.PROD

Sentry.init({
  enabled,
  dist,
  release,
  dsn: 'https://73d23c8a934b4230874f942a79021e49@sentry.avvoka.com/2',
  integrations: [
    new Integrations.BrowserTracing(),
  ],
  tracesSampleRate: 1.0
});